import React , { useRef } from 'react'
import emailjs from "@emailjs/browser";
import Form from 'react-bootstrap/Form';
import "../Css/ContactForm.css"
import {Button} from "react-bootstrap"
const ContactForm = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_qw6352c",
        "template_i4mo10n",
        form.current,
        "cSg8OdvLMf88a1H8o"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
      e.target.reset()
  };
  return (
    <div className='setformbgandpadd'>
      <p className='formhead'>How can we help you?</p>
      <p className='formdis'>Our service representatives are here to help. Send us a <br/>message</p>

      <Form ref={form} onSubmit={sendEmail}>
      <label className='form-label'> First Name*</label>
      <input className="mb-3 form-control" type="text" name="f_name" required />
      <label className='form-label'>Last Name*</label>
      <input className="mb-3 form-control" type="text" name="l_name"required />
      <label className='form-label'>Email Address*</label>
      <input className="mb-3 form-control" type="email" name="user_Email"required />
      <label className='form-label'>Subject*</label>
      <input className="mb-3 form-control" type="text" name="subject" required/>
      <label className='form-label'>Phone Number</label>
      <input className="mb-3 form-control" type="tel" name="telephone" />
      <label className='form-label'>Company Name</label>
      <input className="mb-3 form-control" type="text" name="c_name" />
      <label className='form-label'>Your Query</label><br/>
      <textarea  className='tarea' name="message" rows="5"  />

      {/* <Form.Group className="mb-3" controlId="formGroupPassword" >
        <Form.Label >First Name*</Form.Label>
        <Form.Control type="text" required/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGroupPassword">
        <Form.Label>Last Name*</Form.Label>
        <Form.Control type="text" required/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGroupEmail" >
        <Form.Label>Email Address*</Form.Label>
        <Form.Control type="email" required/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGroupSubject" name='subject'>
        <Form.Label>Subject*</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGroupPassword">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGroupPassword">
        <Form.Label>Company Name</Form.Label>
        <Form.Control type="text" />
      </Form.Group> */}
      {/* <Form.Group className="mb-3" controlId="formGroupPassword">
        <Form.Label>Your Query</Form.Label>
        <Form.Control
          as="textarea"
          style={{ height: '100px' }}
        />
      </Form.Group> */}
        <Button className='contactformbutton' type="submit" value="Send">Send</Button>
    </Form> 
    </div>
  )
}

export default ContactForm
