import React from 'react'
import { AiOutlineClose } from "react-icons/ai";
import {Button} from "react-bootstrap"
import "../Css/Register.css"

const Register = (props) => {
    return (props.trigger)? (
        <div className='regpopup'>
          <div className='regpopupinner'>
            <Button className="closebuttonpop" onClick={() => props.setTrigger(false)}><AiOutlineClose/></Button>
            {props.children}
          </div>
        </div>
      ):"";
}

export default Register
