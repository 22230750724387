import React from 'react'
import Button from 'react-bootstrap/Button';
import {Container, Row, Col} from "react-bootstrap"
import "../Css/Prefooter.css"

const Prefooter = () => {
  return (
    <div className='Prefooter'>
      <Container className='prefoc'>
       <Row>
    
       <Col md={1}></Col>
       <Col md={10} className='sign'>
        <div className='signup'>
        <p className='prehead'>Register For Free</p>
        <p className='predis'>Whether you are a broker, dealer, bank, corporation or individual,
              become a member of the Digital Asset Vault and begin your journey to the safest and easiest 
              method of cryptocurrency ownership.</p>
              
        </div>
       </Col>
       <Col md={1}></Col>
       </Row>
       <Row>
       <Col md={1}></Col>
       <Col md={10} className='signbtn'>
        <Button className="prefooterbtn prefooterbtn-padd"  href="" >Register</Button>
        <Button className="prefooterbtn" variant="outline-success" href="/contact">Contact us</Button>
       </Col>
       <Col md={1}></Col>
       </Row>
      </Container>
    </div>
  )
}

export default Prefooter
