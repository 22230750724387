import React from 'react'
import "../Css/Login.css"
import { AiOutlineClose } from "react-icons/ai";
import {Button} from "react-bootstrap"

const Login = ( props) => {
    return (props.trigger)? (
        <div className='popup'>
          <div className='popupinner'>
            <Button className="closebuttonpop" onClick={() => props.setTrigger(false)}><AiOutlineClose/></Button>
            {props.children}
          </div>
        </div>
      ):"";
}

export default Login
