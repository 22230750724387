import React from 'react'
import Menu from '../Components/Menu'
import Prefooter from '../Components/Prefooter'
import Footer from '../Components/Footer'
import { Container, Row, Col,} from "react-bootstrap"
import "../Css/Privacy.css"
import {TabTitle} from '../../GeneralFunctions'

const Privacy = () => {
    TabTitle('Privacy')
  return (
    <div>
        <Menu/>
        <div className='contbg'>
                <Container>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <p className='ourproh'>Website<span className='ourprocolor'>  Policies</span></p>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                </Container>

            </div>
            <div className='marrgintop'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <p className='privacydis'>
                            Digital Asset Vault is built with the privacy and security needs of its users 
                            at the forefront.  This website is generally for information purposes only and 
                            we vigorously maintain the enforcement of the policies below.  Once you become 
                            a member, additional terms and conditions will apply.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                    <Col md={2}></Col>
                    <Col md={4}>
                    <p className='pggreen'>Privacy Policies</p>
                    </Col>
                    <Col md={4}>
                    <p className='pggreen'>Cookie Policy</p>
                    </Col>
                    <Col md={2}></Col>
                    </Row>
                </Container>
            </div>
        <Prefooter/>
        <Footer/>

      
    </div>
  )
}

export default Privacy
