import React from 'react'
import Menu from '../Components/Menu'
import Prefooter from '../Components/Prefooter'
import Footer from '../Components/Footer'
import { Container, Row, Col,} from "react-bootstrap"
import ContactForm from '../Components/ContactForm'
import "../Css/Contact.css"
import {TabTitle} from '../../GeneralFunctions'

const Contact = () => {
  TabTitle('Contact')
  return (
    <div>
      <Menu/>
      <div className='contbg'>
                <Container>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <p className='ourproh'>Contact<span className='ourprocolor'> US</span></p>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                </Container>

            </div>
            <Container>
              <Row>
                <Col md={3}></Col>
                <Col md={6}>
                  <ContactForm/>
                </Col>
                <Col md={3}></Col>
              </Row>
            </Container>
      <Prefooter/>
      <Footer/>
    </div>
  )
}

export default Contact
