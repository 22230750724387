import React from 'react'
import { images } from '../../Images';
import "../Css/Nav.css"
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import {Image} from "react-bootstrap"
import Navbar from 'react-bootstrap/Navbar';
const Menu = () => {
  return (
    <div>
       <Navbar expand="lg" className="bg-body-tertiary">
      <Container >
        <Navbar.Brand className='logo' href="http://digitalassetvault.co/"><Image src={images.logo} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="/">HOME</Nav.Link>
            <Nav.Link href="/product">OUR SOLUTIONS</Nav.Link>
            <Nav.Link href="/about">ABOUT US</Nav.Link>
            <Nav.Link href="/contact">CONTACT US</Nav.Link>
            <Nav.Link href="/Privacy">POLICIES</Nav.Link>
          </Nav>
         
            <Button className="menubutton"  href="">LOOK UP/REGISTER</Button>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  )
}

export default Menu
