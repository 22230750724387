import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";  
import Home from "./Assets/Pages/Home";
import Product from "./Assets/Pages/Product";
import Contact from "./Assets/Pages/Contact";
import About from "./Assets/Pages/About";
import Privacy from "./Assets/Pages/Privacy";
import Select from "./Assets/Pages/Select";
import Login from "./Assets/Components/Login";
import Register from "./Assets/Components/Register";
import ForgetPassword from "./Assets/Components/ForgetPassword";
import Success from "./Assets/Components/Success";
function App() {
  return (
    <div className="App">
     <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/product" element={<Product/>}></Route>
      <Route path="/about" element={<About/>}></Route>
      <Route path="/contact" element={<Contact/>}></Route>
      <Route path="/privacy" element={<Privacy/>}></Route>
      <Route path="/select" element={<Select/>}></Route>
      <Route path="/login" element={<Login/>}></Route>
      <Route path="/register" element={<Register/>}></Route>
      <Route path="/forgetpassword" element={<ForgetPassword/>}></Route>
      <Route path="/success" element={<Success/>}></Route>


      </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
