import React from 'react'
import { images } from '../../Images';
import {Container, Row, Col,Image} from "react-bootstrap"
import "../Css/Footer.css"

const Footer = () => {
  return (
    <div class="footer">
      <Container>
      <Row className='LogoFooter'>
         <Col md={4}>
            <a href='http://digitalassetvault.co/'><Image className='margsetlogoformbl'  src={images.LogoFooter} width="70%"/></a>
         </Col>
         <Col md={8}></Col>
        
      </Row>
      <Row>
        <Col md={4}>
        <div>
            <p className="mb-4">Contact us</p>
            <p className='footerlinkh'>Mailing Address</p>
            <p className='footerlinkd'>7140 Lemuria Circle, Unit <br/>1002 Naples, Florida 34109</p>
            <p className='footerlinkh'>Phone Number</p>
            <a className="anch" href="tel:+1-315-430-7333" ><p className='footerlinkd'>+1-315-430-7333</p></a>
            <p className='footerlinkh'>Email</p>
            <a className="anch" href="mailto:info@digitalassetvault.co" ><p className='footerlinkd'>info@digitalassetvault.co</p></a>
        </div>
        </Col>
        <Col md={4}><div>
            <p className="mb-4">Links</p>
            <a className="anch" href="/" ><p className='footerlinkd' >Home </p></a>
            <a className="anch" href="/about" ><p className='footerlinkd' >Company</p></a>
            <p className='footerlinkd'>Jobs</p>
            <p className='footerlinkd'>Press</p>
            <p className='footerlinkd'>Blog</p>
            <a className="anch" href="/contact" ><p className='footerlinkd' >Contact Us</p></a>
        </div></Col>
        <Col md={4}><div>
        <p className="mb-4">Token Tool</p>
            <p className='footerlinkd'>Resources</p>
            
        </div></Col>
        
      </Row>
      <Row>
        <hr className='divider'></hr>
      </Row>
      </Container>
    </div>
  )
}

export default Footer
