import logo from "../src/Assets/images/logo.png"
import LogoFooter from "../src/Assets/images/LogoFooter.png"
import union from "../src/Assets/images/union.png"
import vaultone from "../src/Assets/images/vaultone.png"
import union2 from "../src/Assets/images/union2.png"
import vaulttwo from "../src/Assets/images/vaulttwo.png"
import Hero from "../src/Assets/images/Hero.png"
import Vector from "../src/Assets/images/Vector.png"
import Vector1 from "../src/Assets/images/Vector1.png"
import icontext from "../src/Assets/images/icontext.png"
import icon1 from "../src/Assets/images/icon1.png"
import icon2 from "../src/Assets/images/icon2.png"
import icon3 from "../src/Assets/images/icon3.png"
import icon4 from "../src/Assets/images/icon4.png"
import icon5 from "../src/Assets/images/icon5.png"
import icon6 from "../src/Assets/images/icon6.png"
import icon7 from "../src/Assets/images/icon7.png"
import Bottom from "../src/Assets/images/Bottom.png"
import BCW from "../src/Assets/images/BCW.png"
import CyberBonds from "../src/Assets/images/CyberBonds.png"
import OurStory from "../src/Assets/images/OurStory.png"
import team1 from "../src/Assets/images/team1.png"
import tw from "../src/Assets/images/tw.png"
import li from "../src/Assets/images/li.png"
import fb from "../src/Assets/images/fb.png"
import success from "../src/Assets/images/success.png"


export const images={
    logo,LogoFooter,union,vaultone,union2,vaulttwo,Hero,Vector,Vector1,icontext,icon1,icon2,
    icon3,Bottom,BCW,CyberBonds,icon4,icon5,icon6,icon7,OurStory,team1,fb,li,tw,success,
    
}