import React from 'react'
import "../Css/Home.css"
import Menu from '../Components/Menu'
import Footer from '../Components/Footer'
import { Container, Row, Col, Image, Button } from "react-bootstrap"
import { images } from '../../Images'
import Prefooter from '../Components/Prefooter'
import { FaArrowRight } from "react-icons/fa";
import {TabTitle} from '../../GeneralFunctions'
const Home = () => {
    TabTitle('Home')
    return (
        <div className='bg'>
            <Menu />
            <div className='herobg'>
                <Container >
                    <Row>
                        <Col md={6} className='Heading'>
                            <p className='heroheading'>Digital Asset Vault - <br />Everything you need <br />for a lifetime of
                                <span className='greencolortext'> digital <br /> asset security.</span>
                            </p>
                            <p className='herodis'>Purchase, store, track and trade your Digital Assets</p>
                            <Button className="herobtn" href="">Register Now</Button>
                        </Col>
                        <Col md={6} className='heroimg'>
                            <Image src={images.Hero} width="100%" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='ournission'>
                <Container>
                    <Row className='ourmissionpadding'>
                        <Col md={3} className='ourmissionalign'><p className='green'>
                            <span className='greencolortext'> Our </span >Mission
                        </p></Col>
                        <Col md={1}></Col>
                        <Col md={8}><p className='ourmissiondis'>The Digital Asset Vault mission is to provide an intuitive, seamless and secure private trading platform 
                        to any entity or person that desires to own digital assets.
                        
                        </p></Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row className='setpaddforcoldprod'>
                    <Col md={3}></Col>
                    <Col md={6}>
                        <p className='coldproducthead1'>OUR <span className='greencolortext'>PRODUCT</span></p>
                    </Col>
                    <Col md={3}></Col>

                </Row><Row className=''>
                    <Col md={1}></Col>
                    <Col md={10}>
                        <p className='courproductdic'>New or experienced Digital Assets owners can
                         utilize the DAV system to safely store their digital assets. 
                        Trade digital assets using an on-chain exchange or the off-chain 
                        capabilities of the DAV Platform.</p>
                    </Col>
                    <Col md={1}></Col>

                </Row>
                <Row className='productall'>
                    <Col md={4}>
                        <div className='productbg'>
                            <div className='producticon'>
                                <Image src={images.Vector} width="22px" height="21px" />
                            </div>
                            <div className='makebothiconandtextinrow'>
                                <p className="Producthead1">User can manage their <br /> digital assets</p>

                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='productbg1'>
                            <div className='producticon1'>
                                <Image src={images.Vector1} width="22px" height="21px" />
                            </div>
                            <div className='makebothiconandtextinrow'>
                                <p className="Producthead2">User can buy or sell their<br /> digital assets</p>

                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='productbg1'>
                            <div className='producticon1'>
                                <Image src={images.Vector} width="22px" height="21px" />
                            </div>
                            <div className='makebothiconandtextinrow'>
                                <p className="Producthead2">User can simply move their<br /> digital assets</p>

                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>
            {/* <div className='lightgreen'>
                <Container>
                    <Row className='pama'>
                        <Col md={7}>
                            <div>
                                <p className='iconboxhead'>International, Supranational, National, <br />corporate or private entities
                                    can utilize <br />the  <span className='iconbox'> DAV Platform</span> </p>
                            </div>
                        </Col>

                        <Col md={5} className='centergreenbox'>
                            <div className='iconboxAlign'>
                                <div className='icontext1'>
                                    <Image src={images.icontext} width="21px" height="21px" />
                                    <p className='iconboxtext'>
                                        Track digital assets while maintaining privacy within their chosen system deployment parameters.
                                    </p>
                                </div>
                                <div className='icontext1'>
                                    <Image src={images.icontext} width="21px" height="21px" />
                                    <p className='iconboxtext'>
                                        Trade, Promote
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div> */}
            <div className='bgr'>
                <Container>
                    <Row className='setpaddforcoldprod'>
                        <Col md={3}></Col>
                        <Col md={6}>
                            <p className='coldproducthead'>OUR PRODUCT CAN BE USED BY</p>
                        </Col>
                        <Col md={3}></Col>
                    </Row>
                    <Row className='addgapbetweenblocscold align-content-stretch'>
                        <Col md={3} className="tab">
                            <div className='blackbgcold1'>
                                <div className='makeimageandiconinrow1'>
                                    <Image src={images.vaultone} width="60px" height="60px" />
                                    <Image src={images.union2} />
                                </div>

                                <div className='makebothiconandtextinrow'>
                                    <p className="fieldhead1">Financial <br />Institutions</p>
                                    <div className='iconbgfield'><FaArrowRight /></div>
                                </div>
                            </div>
                        </Col>
                        <Col md={3} className="tab">
                            <div className='blackbgcold2'>
                                <div className='makeimageandiconinrow2'>
                                    <Image src={images.vaultone} width="60px" height="60px" />
                                    <Image src={images.union2} />
                                </div>

                                <div className='makebothiconandtextinrow martp'>
                                    <p className="fieldhead1">Private Trading Groups, Corporations & Private Entities</p>
                                    <div className='iconbgfield'><FaArrowRight /></div>
                                </div>
                            </div>
                        </Col>

                        <Col md={3} className="tab">
                            <div className='blackbgcold1'>
                                <div className='makeimageandiconinrow3'>
                                    <Image src={images.vaulttwo} width="60px" height="60px" />
                                    <Image src={images.union2} />
                                </div>
                                <div className='makebothiconandtextinrow'>
                                    <p className="fieldhead2">Legal <br />Industry</p>
                                    <div className='iconbgfield'><FaArrowRight /></div>
                                </div>
                            </div>
                        </Col>

                        <Col md={3} className="tab">
                            <div className='blackbgcold2'>
                                <div className='makeimageandiconinrow4'>
                                    <Image src={images.vaultone} width="60px" height="60px" />
                                    <Image src={images.union2} />
                                </div>
                                <div className='makebothiconandtextinrow'>
                                    <p className="fieldhead3">Insurance <br />companies</p>
                                    <div className='iconbgfield'><FaArrowRight /></div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='howitworks'>
                <Container>
                    <Row className='ourmissionpadding'>
                        <Col md={3} className='ourmissionalign'>
                            <p className='green'>How it
                            <span className='greencolortext'> works </span >
                            </p>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={8}><p className='ourmissiondis'>New or experienced Digital Asset owners can 
                        utilize the DAV system to safely store their digital assets. Trade digital assets
                         using an on-chain exchange or the off-chain capabilities of the DAV Platform.
                        </p></Col>
                    </Row>
                </Container>

            </div>
            <div>
                <Container className='containercolor'>
                    <Row>
                        <p className='sevhead'>
                            <span className='sevheadgreen'>Services</span> tailored for you
                        </p>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div className='icon1'>
                                <Image src={images.icon1} width="42px" height="42px" />
                                <p className='iconh'>
                                    Custody Technology
                                </p>
                                <p className='icond'>
                                The DAV Platform has taken a step back to move forward. We effectively 
                                utilize current regulations and trusted methodologies together with state 
                                of the art wallet technology to provide a scalable and safe platform for
                                 digital asset ownership and trade.
                                </p>
                            </div></Col>

                        <Col md={4}>
                            <div className='icon1'>
                                <Image src={images.icon2} width="42px" height="42px" />
                                <p className='iconh'>
                                    Professional Services
                                </p>
                                <p className='icond'>
                                Whether you are a central bank, commercial bank or financial institution,
                                 trading group, corporation or a novice cryptocurrency enthusiast, let the 
                                 DAV team show you how you can utilize the DAV platform.
                                </p>
                            </div>

                        </Col>
                        <Col md={4}>
                            <div className='icon1'>
                                <Image src={images.icon1} width="42px" height="42px" />
                                <p className='iconh'>
                                    Secure Delivery
                                </p>
                                <p className='icond'>
                                    All DAV products are always delivered securely to the owner
                                    or their chosen custodian.
                                </p>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
            <div className='bottompad'>
                <Container >
                    <Row>
                        <Col md={6} className='Bottomimg'>
                            <Image src={images.Bottom} width="87%" />
                        </Col>
                        
                        <Col md={6} className=''>
                            <p className='bottomh'>Get ahead with <br/>leading products and<br/> expertise
                                from <span className='bottomgreen'> DIGITAL<br/> ASSET vault</span>
                            </p>
                            <Button className="herobtn" href="" >Let’s Start</Button>
                        </Col>

                    </Row>
                </Container>
            </div>
            <Prefooter />
            <Footer />
        </div>
    )
}

export default Home
