import React from 'react'
import Menu from '../Components/Menu'
import Prefooter from '../Components/Prefooter'
import Footer from '../Components/Footer'
import { images } from '../../Images'
import { Container, Row, Col, Image } from "react-bootstrap"
import "../Css/About.css"
import {TabTitle} from '../../GeneralFunctions'
const Product = () => {
    TabTitle('Our Solution')
    return (
        <div>
            <Menu />
            <div className='dotbg'>
            <div className='ourprobg'>
                <Container>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <p className='ourproh'>OUR <span className='ourprocolor'>SOLUTIONS</span></p>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                </Container>

            </div>
            {/* <div className='udpadding'>
                <Container>
                    <Row>
                        <Col md={6}>
                            <p className='head'>ColdVault</p>
                            <p className='discrption'>A ColdVault is a fully secure cold wallet capable of 
                               holding any Digital Asset and at the same time is easily transferable 
                             (on or off-chain).
                            </p>

                            <p className='discrption'>The DAV system ensures the security of a ColdVaul 
                             through the proprietary processes used to create and load the ColdVault.
                             No other cryptocurrency processors are currently offering a high level of 
                             security coupled with the convenience that is provided through the ColdVault
                              product.
                            </p>

                            <p className='discrption'>The fully auditable and insurable system allows for
                             a cryptocurrency investment process that is fully auditable and insurable. 
                             The ColdVault is printed on currency-grade paper, giving hesitant investors
                              a tangible asset to hold on to and store as they please.
                            </p>

                        </Col>
                        <Col md={6}>
                            <Image src={images.BCW} width="80%" />

                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='udpadding'>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className='cyberbonds'>
                                <Image src={images.CyberBonds} width="85%" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <p className='head'>ColdVault Cyber</p>
                            <p className='discrption'>ColdVault Cyber are a form of ColdVault used to pay any
                             crypto ransom demands. Commonly, the victims of ransomware attacks have trouble
                              obtaining the desired cryptocurrency asset to pay off their attackers and 
                              repossess control of their data. ColdVault Cyber ensure that the potential 
                              victims of ransomware attacks, or their insurance providers, have quick 
                              access to funds in the case of an attack.
                            </p>
                            <p className='discrption'>ColdVault Cyber come coupled with a DAV service that 
                             assists in transferring the funds to the desired cryptocurrency asset and 
                             delivering them to the attacker. This ensures the safe return of a company’s 
                             data and avoids additional expenditures for businesses and their insurance
                             providers in the event of a total loss.
                            </p>

                        </Col>

                    </Row>
                </Container>
            </div> */}
            

            <div className='proboxbg'>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className='productcol'>
                                <div className='productic'>
                                    <Image src={images.icon1} width="42px" height="42px" />
                                </div>
                                <div className='makeinarow'>
                                    <p className="Prohead">Custody Technology</p>
                                    <p className="Prodisc">The DAV Platform has taken a step back to move forward.
                                        We effectively utilize current regulations and trusted methodologies together
                                        with state of the art cold wallet technology to provide a scalable and safe
                                        platform for digital asset ownership and trade.</p>

                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='productcol'>
                                <div className='productic'>
                                    <Image src={images.icon2} width="42px" height="42px" />
                                </div>
                                <div className='makeinarow'>
                                    <p className="Prohead">Professional Services</p>
                                    <p className="Prodisc">Let the Digital Asset Vault team show you how our platform
                                        can help your organization develop a robust digital asset strategy. In addition
                                        to our “off the shelf” product and platform, we offer consulting services to
                                        suit your specific needs.</p>

                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className='productcol'>
                                <div className='productic'>
                                    <Image src={images.icon4} width="42px" height="42px" />
                                </div>
                                <div className='makeinarow'>
                                    <p className="Prohead">On-Chain Exchange</p>
                                    <p className="Prodisc">All transactions conducted in the DAV Platform
                                     can be performed on-chain utilizing our integrated exchange, thereby
                                      expanding the user base to anyone in the world with access to an 
                                      exchange. Our exchange partner maintains money transmission licenses
                                       in over 200 counties worldwide</p>

                                </div>
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className='productcol-padd'>
                                <div className='productic'>
                                    <Image src={images.icon1} width="42px" height="42px" />
                                </div>
                                <div className='makeinarow'>
                                    <p className="Prohead">Compatibility</p>
                                    <p className="Prodisc">The DAV Platform operates independent of existing legacy 
                                    systems and is compatible through API connections. Financial institutions and 
                                    corporations alike can operate on the platform without integration or security
                                     impediments.</p>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='proboxbg1'>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className='productcol'>
                                <div className='productic'>
                                    <Image src={images.icon1} width="42px" height="42px" />
                                </div>
                                <div className='makeinarow'>
                                    <p className="Prohead">Interoperability</p>
                                    <p className="Prodisc">Interoperability with other systems and platforms 
                                    such as integrating with existing decentralized finance (DeFi) protocols 
                                    or other relevant projects within the blockchain ecosystem.</p>

                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='productcol'>
                                <div className='productic'>
                                    <Image src={images.icon5} width="42px" height="42px" />
                                </div>
                                <div className='makeinarow'>
                                    <p className="Prohead">Product Registration</p>
                                    <p className="Prodisc">Ability to register DAV products where required 
                                    for complying with regulatory requirements, establishing the necessary 
                                    smart contracts, and recording relevant details for transparency and 
                                    audibility.</p>

                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className='productcol'>
                                <div className='productic'>
                                    <Image src={images.icon6} width="42px" height="41px" />
                                </div>
                                <div className='makeinarow'>
                                    <p className="Prohead">Smart Contracts</p>
                                    <p className="Prodisc">Works with smart contracts utilizing proper security measures,
                                     ensuring the safety and integrity of the transactions and participants' assets.</p>

                                </div>
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className='productcol'>
                                <div className='productic'>
                                    <Image src={images.icon7} width="42px" height="41px" />
                                </div>
                                <div className='makeinarow'>
                                    <p className="Prohead">Token Swapping Mechanism</p>
                                    <p className="Prodisc">Securely and efficiently swapping ERC-20 tokens
                                     into ERC-721 tokens (or tokens with similar properties).</p>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            </div>
            <Prefooter />
            <Footer />
        </div>
    )
}

export default Product
