import { React, useState } from 'react'
import Menu from '../Components/Menu'
import Form from 'react-bootstrap/Form';
import Prefooter from '../Components/Prefooter'
import Footer from '../Components/Footer'
import { images } from '../../Images'
import { Container, Row, Col, Button,Image } from "react-bootstrap"
import "../Css/Select.css"
import Login from "../Components/Login"

import ForgetPassword from '../Components/ForgetPassword';
import {TabTitle} from '../../GeneralFunctions'

const Select = () => {
    TabTitle('Select')
    const [buttonPopup, setButtonPopup] = useState(false);
    const [regPopup, setregPopup] = useState(false);
    const [forgetPopup, setforgetPopup] = useState(false);
    const [successPopup, setsuccessPopup] = useState(false);
    return (
        <div className='sbgdot'>
            <Menu />
            <div >
                <Container className='spadding'>
                    <Row>

                        <Col md={1}></Col>
                        <Col md={5} className='responsive'>
                            <div className='greenwhitealign'>
                                <p className='greenbroker' onClick={() => setButtonPopup(true)}> broker / dealer</p>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className='greenwhitealign'>
                                <p className='whiteinverster' onClick={() => setButtonPopup(true)}>client / investor</p>
                            </div>
                        </Col>
                        <Col md={1}></Col>

                    </Row>
                </Container>
                <Login trigger={buttonPopup} setTrigger={setButtonPopup}>
                    {/* <div className='setformbgandpadd'> */}
                    <p className='formhead'>Login</p>

                    <Form>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formGroupPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" />
                        </Form.Group>
                        <p className='forgetpass' onClick={() => setforgetPopup(true)}>Forgot password?
                        </p>
                        <ForgetPassword trigger={forgetPopup} setTrigger={setforgetPopup}>

                            <Form>
                                <p className='formhead'>change your password</p>
                                <Form.Group className="mb-3" controlId="formGroupEmail">
                                    <Form.Label>Broker ID</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGroupEmail">
                                    <Form.Label>Current Password*</Form.Label>
                                    <Form.Control type="password" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGroupEmail">
                                    <Form.Label>New Password*</Form.Label>
                                    <Form.Control type="password" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGroupEmail">
                                    <Form.Label> Confirm New Password*</Form.Label>
                                    <Form.Control type="password" />
                                </Form.Group>
                                <Button className='loginformbutton' onClick={() => setsuccessPopup(true)}>Send</Button>
                                <Form>
                                     <Login trigger={successPopup} setTrigger={setsuccessPopup}>
                                        <div className='successpop'>
                                            <Image src={images.success} width="77px" height="81px" />
                                            <p className='successhead'>Success</p>
                                            <p className='successdis'>Your password has been changed</p>
                                            <Button className='loginformbutton' >Return to login</Button>
                                            
                                            

                                        </div>
                                        
                                        
                                    </Login>
                                 </Form>
                            </Form>
                        </ForgetPassword>
                        <Button className='loginformbutton'>Send</Button>
                        <p className='loginformtext'>Don’t have an account? Click
                            <span className='reghere' onClick={() => setregPopup(true)}> here </span>
                            to register<br /> as a verified broker.</p>

                        <Login trigger={regPopup} setTrigger={setregPopup}>
                            {/* <div className='setformbgandpadd'> */}
                            <Container>
                                <Row>
                                    <Col md={2}></Col>
                                    <Col md={8}> <p className='formhead' >broker application</p></Col>
                                    <Col md={2}></Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form>
                                            <Form.Group className="mb-3" controlId="formGroupText">
                                                <Form.Label >First Name</Form.Label>
                                                <Form.Control type="text" required />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                <Form.Label>Last Name*</Form.Label>
                                                <Form.Control type="text" required />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formGroupEmail">
                                                <Form.Label>Email Address</Form.Label>
                                                <Form.Control type="email" required />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                <Form.Label>FINRA License</Form.Label>
                                                <Form.Control type="text" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                <Form.Label>Brokerage/Organization</Form.Label>
                                                <Form.Control type="text" />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col md={6} className='regl'>
                                            <div className="regtext">
                                                <p className="regtextbox">Tell us about your expected volume of cryptocurrency and type.
                                                    Multiple types and volumes are acceptable.</p>
                                            </div>
                                            <Button className='regformbutton' >Submit</Button>
                                        
                                    </Col>



                                </Row>

                            </Container>


                        </Login>
                    </Form>


                </Login>
            </div >
            <Prefooter />
            <Footer />


        </div >
    )
}

export default Select
