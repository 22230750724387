import React from 'react'
import Menu from '../Components/Menu'
import Prefooter from '../Components/Prefooter'
import Footer from '../Components/Footer'
import { images } from '../../Images'
import { Container, Row, Col, Image } from "react-bootstrap"
import "../Css/Aboutus.css"
import {TabTitle} from '../../GeneralFunctions'

const About= () => {
    TabTitle('About')
    return (
        <div>
            <Menu />
            <div className='ourprobg'>
                <Container>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <p className='ourproh'>About <span className='ourprocolor'>US</span></p>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                </Container>

            </div>
            <div className='udpadding'>
                <Container>
                    <Row>
                        <Col md={6}>
                            <p className='oshead'>our story</p>
                            <p className='osdis'>We originally set out to help individuals safely buy
                                and store cryptocurrencies in a market that was littered with horror stories of
                                crypto theft and expensive mistakes. </p>
                                <p className='osdis'>Soon we recognized that institutional 
                                or corporate decision makers were similarly not crypto savvy and likewise needed 
                                a safe solution to purchase and store cryptocurrencies. Those same institutional 
                                and corporate entities demanded privacy and speed in digital asset transactions. 
                                That is where the DAV Platform was born and thrives.</p>

                            <p className='osdis'>As of 2023, the market size of cryptocurrency is approximately $1.3 
                            trillion with expected growth to over $5 trillion by 2028. This means the introduction to
                             a significant population that currently do not have knowledge on how to safely buy and 
                             hold digital assets. While there are advancements in the marketplace of digital assets,
                              most of today’s solutions are more technical and complicated. DAV was created asking the 
                              question “Can my 80+ year old mother use this and what current system does she know?”</p>


                        </Col>
                        <Col md={6}>
                            <Image src={images.OurStory} width="100%" />

                        </Col>
                    </Row>
                    <Row>
                    <Col md={12}>
                         <p className='osdis'>So we created a system for ordinary people to participate in the 
                         cryptocurrency market without deep knowledge on how to buy or sell cryptocurrency or 
                         even how to open a wallet. Using current financial system infrastructure, the DAV 
                         Platform is designed to facilitate secure and efficient ownership and trading of 
                         Digital Assets for use by central banks, commercial banks, broker dealers, supranational
                          organizations, trading groups, institutional investors, corporate or individual customers.</p>
                          <p className="osdis">At the same time, we at DAV recognized the benefits of blockchain and the 
                          new approach to financial world with decentralized finance. So although the Digital Asset Vault
                           is a platform and cold storage using the existing infrastructure of financial systems, it does 
                           NOT run on the existing financial system. The DAV Platform is an easy entry and management tool 
                           utilizing blockchain technology and decentralized finance in every transaction. It is truly the 
                           best of both worlds!</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            
            <Prefooter />
            <Footer />

        </div>
    )
}

export default About
